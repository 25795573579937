import { AnimationProps } from "./types";

const Animation = ({ axis, reverse, name }: AnimationProps) => {
  return (
    <style>
      {`
        @keyframes ${name} {
          from {
            transform: translate${axis || "X"}(${reverse ? 0 : -50}%);
          }
          to {
            transform: translate${axis || "X"}(${reverse ? -50 : 0}%);
          }
        }
      @media (prefers-reduced-motion) {
          animation: none;
      }
      `}
    </style>
  );
};

export default Animation;
