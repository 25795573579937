import clsx from "clsx";
import { useId, useRef } from "react";
import Animation from "./animation";
import styles from "./styles.module.scss";
import { MarqueeComponentProps } from "./types";

const MarqueeComponent = ({
  axis,
  reverse = false,
  align,
  background,
  duration = 5000,
  height,
  width,
  className,
  children,
  showOpacity,
}: MarqueeComponentProps) => {
  const lastEl = useRef<HTMLDivElement | null>(null);
  let id = useId();
  if (id) {
    id = `${id}`.replace(/:/g, "-");
  }

  const offsetValues = [0, 1];
  const before =
    "before:absolute before:left-none before:z-10 before:content-[''] before:h-full before:w-2m before:m:w-xl";
  const after =
    "after:absolute after:right-none after:z-10 after:content-[''] after:h-full after:w-2m after:m:w-xl";

  const animationName = `m${id}slide${axis}`;
  return (
    <div
      className={clsx(className, "overflow-hidden relative", {
        [styles.opacity_bg as string]: showOpacity,
        [before]: showOpacity,
        [after]: showOpacity,
      })}
      style={{
        backgroundColor: background ?? "transparent",
        height: height ?? lastEl?.current?.offsetHeight,
        width: width ?? "100%",
      }}
    >
      <div
        className={clsx("flex absolute", {
          "flex-row  min-w-max": axis === "X",
          "flex-col": axis === "Y",
        })}
        style={{
          animation: `${animationName} ${duration}ms linear infinite`,
          animationPlayState: "running",
        }}
      >
        <Animation reverse={reverse} axis={axis} name={animationName} />

        {offsetValues.map((offset) => (
          <div
            ref={lastEl}
            key={offset}
            className={clsx(
              "row h-full whitespace-nowrap flex justify-around flex-none",
              {
                "flex-row-reverse": axis === "X" && reverse,
                "flex-row": axis === "X" && !reverse,
                "flex-col-reverse": axis === "Y" && reverse,
                "flex-col": axis === "Y" && !reverse,
              },
            )}
            style={{
              alignItems: align === "center" ? "center" : `flex-${align}`,
            }}
          >
            {children}
          </div>
        ))}
      </div>
    </div>
  );
};

/** More here  https://github.com/jagnani73/react-easy-marquee/tree/main/src */
const Marquee = ({
  axis,
  reverse,
  align,
  background,
  duration,
  height,
  width,
  className,
  children,
  showOpacity,
}: MarqueeComponentProps) => {
  return (
    <MarqueeComponent
      axis={axis}
      reverse={reverse}
      align={align}
      background={background}
      duration={duration}
      height={height}
      width={width}
      className={className}
      showOpacity={showOpacity}
    >
      {children}
    </MarqueeComponent>
  );
};

export default Marquee;
