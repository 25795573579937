import { ComponentSectionsHorizontalRunningLinesV2 } from "@lib/generated/graphql";
import Marquee from "@modules/homepage/components/Marquee";
import { FC } from "react";

type HorizontalRunningLinesProps = {
  data: ComponentSectionsHorizontalRunningLinesV2;
};

const HorizontalRunningLines: FC<HorizontalRunningLinesProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  const { horizontalRunningLineItems } = data;
  /* eslint-disable @next/next/no-img-element */
  return (
    <section className="my-3xl m:my-[120px]">
      <Marquee
        className="h-2m m:h-[40px]"
        showOpacity
        axis="X"
        duration={horizontalRunningLineItems.length * 4000}
        reverse
      >
        {horizontalRunningLineItems?.map((item, index) => {
          if (!item) {
            return null;
          }
          const image = item.image.data?.attributes;
          const imageElement = (
            <img
              width={image?.width || undefined}
              height={image?.height || undefined}
              src={image?.url + "?h=80"}
              alt={image?.alternativeText || ""}
              className="h-2m"
            />
          );

          return (
            <div key={index} className="px-3s flex-none">
              {item.link ? (
                <a
                  href={item.link}
                  className="hover:opacity-80 cursor-pointer"
                  target="_blank"
                >
                  {imageElement}
                </a>
              ) : (
                imageElement
              )}
            </div>
          );
        })}
      </Marquee>
    </section>
  );
};

export default HorizontalRunningLines;
